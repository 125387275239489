import md5 from "crypto-js/md5";
import { set } from "@vueuse/core";

interface UserInfo {
  name?: string;
  email?: string;
  age?: number;
}

interface LogInParams {
  pass: string;
}

interface UserPreference {
  // Додайте тут властивості, які повертає ваш API
  // Наприклад:
  theme: string;
  language: string;
}

interface ApiResponse {
  preference: UserPreference;
}

export const useAuthStore = defineStore('authStore', () => {
  const username: Ref<string | null> = useCookie('username');
  const token = useCookie('token');
  const userInfo: Ref<UserInfo> = ref({});
  const config: RuntimeConfig = useRuntimeConfig();
  const exoClickUser = ref({});
  const trafficStarsUser = ref({});

  const isAuthenticated = computed(() => toValue(token)?.length);
  const setUserInfo = (info = {}) => set( userInfo, info);

  const logIn = async ({ pass }: LogInParams): Promise<void> => {
    return getUserInfo(pass).then((res) => {
      setUserInfo(res.preference);
      set(token, md5(pass).toString());
    })
  }

  async function getUserInfo(pass?: string) {
    return await $fetch('/api/user/get-info', {
      query: {
        token: toValue(token) ?? md5(pass).toString(),
        username: toValue(username),
      }
    })
  }

  async function loginExoClick () {
    const data = await $fetch('https://api.exoclick.com/v2/login', {
      method: 'POST',
      body: {
        "api_token": config.public.token.exoClick,
      }
    });
    await $fetch('https://api.exoclick.com/v2/user', {
      headers: {
        Authorization: `Bearer ${data.token}`
      }
    })

    set(exoClickUser, data);
  }

  async function loginTrafficStars() {
    const data = await $fetch('/api/traffic-stars/login');
    set(trafficStarsUser, data);
  }

  async function getAllInfo() {
    await Promise.all([
      loginExoClick(),
      getUserInfo(),
      loginTrafficStars(),
    ])
  }

  const singOut = () => {
    set(username, null);
    set(token, null);
  }


  const setUsername = (name: string) => {
    set(username, name)
  };
  return {
    username,
    token,
    userInfo,
    isAuthenticated,
    exoClickUser,
    trafficStarsUser,
    logIn,
    setUsername,
    singOut,
    getAllInfo,
    loginExoClick,
    loginTrafficStars,
  }
})
